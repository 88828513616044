<template>
  <div class="content-text">
    <h3>小蜜蜂用户协议</h3>
    <p>更新时间：2022年03月29日 生效时间：2022年03月30日</p>
    <h4>一、导语</h4>
    <p>
      (一)
      【审慎阅读】你在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，你应重点阅读。如你对协议有任何疑问，可向小蜜蜂客服咨询。
      (二)  【签约动作】当你按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后或以其他方式选择接受本协议或直接使用了本协议相关服务，即表示你已充分阅读、理解并接受本协议的全部内容，并与小蜜蜂达成一致，成为小蜜蜂“用户”。阅读本协议的过程中，如果你不同意本协议或其中任何条款约定，你应立即停止注册程序。
    </p>
    <h4>二、定义</h4>
    <p>
      (一)  【小蜜蜂】小蜜蜂指研发并提供小蜜蜂产品和服务的深圳市凯达来科技有限公司，以及其现在或未来设立的相关关联公司的单称或合称，以下称为“小蜜蜂”或“我们”。
      (二)
      【小蜜蜂软件服务】小蜜蜂软件服务指SaaS软件服务，包括小蜜蜂开发和服务的小蜜蜂后台、小蜜蜂平台、小蜜蜂app、小蜜蜂管家端app等全面帮助物业管理、运营小区日常业务、工作的SaaS软件产品，作为第三方服务集成在小蜜蜂关联公司或其他第三方软件和/或智能硬件而提供的服务。
      (三)
      【小蜜蜂关联经营主体】小蜜蜂关联经营主体是指和小蜜蜂一起共同提供服务的各法律主体，小蜜蜂可能根据业务调整而变更经营主体，变更后的经营主体与你共同履行本协议并向你提供产品或服务，经营主体的变更不会影响你本协议项下的权益。为了更好的为你提供服务，小蜜蜂可能会依据市场变化或经营便利自主安排或指定其关联公司、控股公司或小蜜蜂认可的第三方公司提供部分或全部的相关服务，如订单查询功能，你可在小蜜蜂精选查询你在小蜜蜂发生的所有订单记录，如你享受上述服务，你将另行与第三方签署相应的协议。
      (四)  【用户（以下或称为“你”）】用户包括物业和小区住户，商家是指在小蜜申请通过后有店铺的公司、个人以及其他合法组织。
       
    </p>
    <h4>三、协议范围</h4>
    <p>
      (一) 
      【平等主体】本协议由你与小蜜蜂共同缔结，本协议对你与小蜜蜂均具有合同效力。
      (二)  【协议变更】小蜜蜂对本协议内容进行变更的，会按照法定程序在小蜜蜂控制台、物业后台的消息通知中进行公示，若你在本协议内容公示生效后继续使用本服务的，表示你已充分阅读、理解并接受变更后的协议内容，也将遵循变更后的协议内容继续使用小蜜蜂服务；若你不同意变更后的协议内容，你应立即停止使用本服务。如您对协议有任何疑问，可向小蜜蜂客服咨询。
      (三)
      【补充协议】由于互联网高速发展，你与小蜜蜂签署的本协议列明的条款并不能完整罗列并覆盖你与小蜜蜂所有权利与义务，现有的约定也不能保证完全符合未来发展的需求，因此，小蜜蜂saas系统公示的《隐私政策》等相关协议以及规则/规范均为本协议的补充协议，与本协议不可分割且具有同等法律效力，若你使用本服务，视为你同意上述补充协议。
       
    </p>
    <h4>四、帐号注册与使用规范</h4>
    <p>
      (一)
      【用户资格】你确认在你注册小蜜蜂帐号并使用小蜜蜂产品或服务前，你是具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人、法人或其他组织。如果你因年龄、智力等因素而不具有相应的民事行为能力，请在法定监护人的陪同下阅读和判断是否同意本协议。如果你是非中国大陆地区的用户，你订立或履行本协议以及使用本服务的行为还需要同时遵守你所属和/或所处国家或地区的法律。
      (二)  帐号注册 1.【帐号获得】你可通过下述方式获得你的小蜜蜂帐号：
      1)当你按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，你可获得小蜜蜂帐号并成为小蜜蜂用户。
      2)当你通过第三方授权登录程序进入小蜜蜂，你授权小蜜蜂收集你的第三方帐号昵称及头像等标识化的个人信息，授权完成后，你可获得小蜜蜂帐号并成为小蜜蜂用户。
      2.【帐号使用】为方便用户登录，你可通过你的帐号登陆小蜜蜂域名下的其他服务，如有特殊情况，具体以实际登陆情况为准。
      3.【同一用户】使用同一身份认证信息、同一手机号或经小蜜蜂排查认定多个小蜜蜂帐号的实际控制人为同一人的，均视为同一用户。
      4.【信息真实】你应当通过真实身份信息认证注册帐号，且你提交的帐号名称、头像、
      简介等注册信息中不得出现违法和不良信息，如存在上述情况，小蜜蜂将不予注册；同时，在注册后，如发现你以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法/不良信息的，或侵犯他人合法权益的，小蜜蜂可采取限期改正、暂停使用、注销登记、收回等措施。
      (三) 帐号安全
      1.【安全保管义务】你有权使用手机号或第三方账号授权登录小蜜蜂，获得账号并自行设置符合安全要求的密码，你的帐号由你自行保管，小蜜蜂任何时候均不会主动要求你提供你的帐号密码。因此，请你务必保管好你的帐号，并确保你在每个上网时段结束时退出登录并以正确步骤离开小蜜蜂。帐号因你主动泄露或因你遭受他人攻击、诈骗等行为导致的损失及后果，小蜜蜂并不承担责任，你应通过司法、行政等救济途径向侵权行为人追偿。
      2.【帐号行为责任自负】由于您的小蜜蜂账号关联您的个人信息及商业信息，请确保您小蜜蜂账号仅限您本人使用，以保证账号的安全性和私密性。你应对你帐号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品/服务及披露信息等）负责，但除小蜜蜂存在过错的除外。
      3.如发现任何未经授权使用你帐号登录小蜜蜂或其他可能导致你帐号遭窃、遗失的情况，建议你立即通知小蜜蜂。你理解小蜜蜂对你的任何请求采取行动均需要合理时间，且小蜜蜂应你请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除小蜜蜂存在法定过错外，小蜜蜂不承担责任。
      (四)【帐号转让】由于用户帐号关联用户信用信息，仅当有法律明文规定、司法裁定或经小蜜蜂同意，并符合小蜜蜂用户帐号转让流程的情况下，你可进行帐号的转让。你的帐号一经转让，该帐号项下权利义务一并转移。除此外，你的帐号不得以任何方式转让，否则小蜜蜂有权追究你的违约责任，且由此产生的责任及后果均由你自行承担。
      (五)【资料更新】你应当及时更新你提供的信息，在法律有明确规定要求小蜜蜂作为技术服务提供者必须对部分用户的信息进行核实的情况下，小蜜蜂将依法不时地对你的信息进行检查核实，你应当配合提供最新、真实、完整、有效的信息。
      (六)【帐号找回】因你个人原因导致的帐号信息遗失，如需找回帐号信息，请按照帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，小蜜蜂有权拒绝提供帐号找回服务；若帐号的唯一凭证不再有效，小蜜蜂有权拒绝支持帐号找回，例如手机号二次出售，小蜜蜂可拒绝支持帮助找回原手机号绑定的帐号。
      (七)【帐号注销】在需要终止使用帐号服务时，符合以下条件的，你可以申请注销你的帐号：
      1.你申请注销的是你本人的帐号，并依照小蜜蜂的相关流程提交相应的资料进行注销；
      2.你应对你在注销帐号前的使用行为承担相应责任；
      3.注销成功后，你的帐号信息、个人身份信息、交易记录、会员权益等将无法恢复。你应自行备份存储在本软件和/或本服务中的数据、信息或与本软件和/或本服务相关的数据、信息。
       
    </p>
    <h4>五、小蜜蜂服务内容及使用规范</h4>
    <p>
      (一)【服务概况】你可以用户身份通过小蜜蜂提供的软件服务在小蜜蜂系统上享受商品及/或服务的浏览、发布、购买与评价、申请、审核争议处理、信息交流等服务，也可通过小蜜蜂提供的软件服务发布任务、以住户身份提交工单并与物业服务人员沟通，但具体的交易及是否确认、支付行为由你自行决定。
      (二)【商品/服务的购买】当你购买小蜜蜂商家所上架的商品及/或服务时，请你务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实你的联系地址、电话、收货人等信息。如你填写的收货人非你本人，则该收货人的行为和意思表示产生的法律后果均由你承担。
      你的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱小蜜蜂正常交易秩序的行为。基于维护交易秩序及交易安全的需要，小蜜蜂发现上述情形时可主动执行关闭订单、限制购买、锁定/冻结帐号等操作。
      (三)【商品/服务的评价】小蜜蜂为用户提供了评价系统，你可对与你达成交易的商品及/服务进行评价。你的评价行为应遵守的《中华人民共和国网络安全法》等相关法律规定，包括但不限于：评价内容应当合法、客观、真实，与交易的商品/服务具有关联性，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息，且不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索等；否则小蜜蜂可根据相关规定通知监管部门对你实施上述行为采取相应的处置措施。
      (四)【收货后结算服务】即第三方代收代付功能：在资金支付完成前，交易相对方不可撤销地授权小蜜蜂支付（或小蜜蜂支付的协议银行）进行资金的代为收取、支付、存放、划转（该服务无法提供给即时到账、储值卡充值等未经小蜜蜂支付结算资金的业务）。在使用该功能时，买家点击确认收货或根据买卖双方的约定视为确认收货后，小蜜蜂支付将代为收取的买家支付的款项代为支付给卖家。
       
    </p>
    <h4>六、用户的违约及处理</h4>
    <p>
      (一) 违约的认定 1.使用小蜜蜂服务时违反有关法律法规规定的；
      2.违反本协议或小蜜蜂规则（即本协议第二条）约定的；
      3.为适应小区服务、电子商务发展，为广大用户提供高效优质服务，你理解并同意，其他情节影响严重，小蜜蜂有合理理由认定你违约的，如你不能提供充分举证和合理解释，则视为你违约。
      (二)【禁止性信息】你在使用小蜜蜂服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的商品、服务或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：
      1.违反国家法律法规禁止性规定的；
      2.政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
      3.欺诈、虚假、不准确或存在误导性的；
      4.侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
      5.侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
      6.存在可能破坏、篡改、删除、影响小蜜蜂任何系统正常运行或未经授权秘密获取小蜜蜂及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
      7.其他违背社会公共利益或公共道德或依据相关小蜜蜂协议、规则的规定不适合在小蜜蜂上发布的。
      (三)【违反约定】如你的行为使小蜜蜂及其关联公司遭受损失（包括直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），你应赔偿小蜜蜂及其关联公司的上述全部损失；如你的行为使小蜜蜂及其关联公司遭受第三人主张权利，小蜜蜂及其关联公司可在对第三人承担金钱给付等义务后就全部损失向你追偿。如你违反本协议或小蜜蜂规则约定的，小蜜蜂有权经综合评估后视你的行为性质，采取包括但不限于删除你发布的商品或服务、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。
      (四)【服务终止】你需要对自己在服务使用过程中的行为负责。即使你已经终止使用该服务或完成了相应的交易或删除你所拥有的店铺，你仍应对你的之前的行为负责，你承担责任的形式包括但不限于：对受到侵害者进行赔偿，以及小蜜蜂承担了因你的行为导致的行政处罚或侵权损害赔偿责任后，你应给予小蜜蜂等额的赔偿（如有）。
      (五)【关联处理】如因你严重违约导致小蜜蜂终止本协议时，出于维护小蜜蜂秩序及保护消费者权益的目的，小蜜蜂及/或其关联公司可对与你在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第十一条约定的方式通知你。如小蜜蜂与你签署的其他协议及小蜜蜂及/或其关联公司、支付公司与你签署的协议中明确约定了对你在本协议项下合作进行关联处理的情形，则小蜜蜂出于维护小蜜蜂秩序的目的，可在收到指令时中止甚至终止协议，并以本协议第十一条约定的方式通知你。
       
    </p>
    <h4>七、交易争议处理</h4>
    <p>
      (一)【小蜜蜂介入】小蜜蜂可根据实际情况介入处理你与其他用户之间的纠纷。
      1)你在交易过程中与其他用户发生争议的，你或其他用户中任何一方可以自行协商解决，也可以通过小蜜蜂介入处理，若你觉得以上的方式均无法达到你要的结果，你可以寻求司法机关或其他机关的协助。
      2)你选择小蜜蜂介入的方式处理相关问题，那么你需要遵守和执行小蜜蜂的处理结果。若你对小蜜蜂的处理结果不满意，你仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，你仍应先履行小蜜蜂的处理结果。
      3)【调处决定】你理解并同意，小蜜蜂进行交易争议介入的，小蜜蜂的客服及维权人员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除小蜜蜂存在故意或重大过失外，小蜜蜂对争议调处决定免责。
      (二)【赔偿限制】如根据小蜜蜂客服介入后判定小蜜蜂商家承担责任的，小蜜蜂商家的因各种理由导致无法赔付的，由你自行和小蜜蜂商家协商或其他途径解决。
       
    </p>
    <h4>八、免责声明</h4>
    <p>
      (一)小蜜蜂依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，小蜜蜂无需为此承担任何责任：
      1.小蜜蜂需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，在小蜜蜂已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
      2.因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令、疫情等不可抗力因素。
      3.基础运营商或主管部门故障、调整导致之影响。
      4.因政府管制而造成的暂时性关闭、服务调整等。
      5.你的电脑软硬件和通信线路、供电线路出现故障的。
      6.因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。
      7.尽管有前款约定，小蜜蜂将采取合理行动积极促使服务恢复正常。
      (二)【海量信息】小蜜蜂根据本协议向你提供小蜜蜂软件服务，你了解小蜜蜂saas系统以及商品信息系商家或用户自行发布，且可能存在风险和瑕疵。小蜜蜂将通过依法建立相关检查监控制度尽可能保障你的合法权益及良好体验。同时，鉴于网络服务具备存在海量信息及信息网络环境下信息与实物相分离的特点，小蜜蜂无法逐一审查商品/服务的信息，无法逐一审查交易所涉及的商品/服务的质量、安全以及合法性、真实性、准确性，对此你应谨慎判断。
       
    </p>
    <h4>九、服务终止</h4>
    <p>
      (一)【终止的情形】本服务可能会因为下述情形终止：
      1.小蜜蜂变更本服务内容而你明示不愿接受变更事项的。
      2.你明示不愿继续使用小蜜蜂服务，且符合小蜜蜂终止服务条件的。
      3.你违反本协议约定，小蜜蜂依据违约条款或小蜜蜂规则终止本协议的。
      4.除上述情形外，因你多次违反小蜜蜂规则相关规定且情节严重，小蜜蜂依据本协议或小蜜蜂规则停止向你提供服务的。
      5.其他小蜜蜂基于合理理由认为应当终止服务的情况。
      (二)【用户信息披露】本协议终止后，除法律有明确规定外，小蜜蜂无义务向你或你指定的第三方披露你帐户中的任何信息。
      (三)【小蜜蜂权利】本协议终止后，小蜜蜂仍享有下列权利：
      1.继续保存你留存于小蜜蜂域内，根据法律法规的要求应当保存的信息；
      2.对于你过往的违约行为，小蜜蜂仍可依据本协议向你追究违约责任。
      (四)【交易争议处理】本协议终止后，对于你在本协议存续期间因提供的各项服务而产生的交易订单，小蜜蜂可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则你应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。
       
    </p>
    <h4>十、知识产权</h4>
    <p>
      (一)小蜜蜂或其关联方拥有、使用、许可、控制的或者小蜜蜂或其关联方对之享有其他权利的所有知识产权、工业产权和专有权利，如你需要使用，可按照官网公示的途径向小蜜蜂申请，非经小蜜蜂或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在小蜜蜂网站上的程序或内容。你不得恶意申请和注册与小蜜蜂及小蜜蜂关联方相同或近似的商标、名称、标识、标志、微信公众号、域名、网页等，用于开展与小蜜蜂及小蜜蜂关联方实质相同或近似的业务。
      (二)如你需使用著作权非小蜜蜂所有的内容的，你应获得具体内容的著作权所有者的合法授权才能使用，如因你私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由你自行承担责任，包括但不限于你自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。
       
    </p>
    <h4>十一、通知与送达</h4>
    <p>
      (一)【通知实时关注】小蜜蜂根据你提供的任一联系方式送达各类通知，而此类通知的内容可能对你的权利义务产生重大的有利或不利影响，请你务必及时关注。
      (二)【法律文书送达】对于在小蜜蜂saas系统内因交易活动引起的任何纠纷，你同意司法机关及监管部门（包括但不限于人民法院、市场监督管理局）可以通过你在小蜜蜂享受各项服务时提供的手机号码、电子邮箱联系方式以及在注册小蜜蜂用户时生成的帐号进行送达，司法机关向上述联系方式发出法律文书即视为送达。你指定的邮寄地址为你的法定身份证地址、注册地址或你提供的有效联系地址。
      (三)【联系方式实时更新】你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不正确，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由你自行承担由此可能产生的法律后果。
       
    </p>
    <h4>十二、法律适用、管辖与其他</h4>
    <p>
      (一)【法律适用】本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。
      (二)【管辖法院】你因使用小蜜蜂服务所产生及与小蜜蜂服务有关的争议，由小蜜蜂与你协商解决。协商不成时，任何一方均可向深圳互联网法院提起诉讼。
      (三)【条款独立性】如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。
      (四)【权利放弃】本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。小蜜蜂未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
    </p>
  </div>
</template>

<script>
export default {
  name: "admin",
};
</script>

<style lang="less">
.content-text {
  width: 900px;
  margin: 10px auto 0;
  padding-bottom: 60px;
  color: #333;
  font-size: 14px;
  line-height: 2em;
}
.content-text .agreement-underline {
  font-weight: bold;
  text-decoration: underline;
}
.content-text .agreement-rule span {
  font-weight: bold;
}
.content-title {
  width: 900px;
  margin: 0 auto;
  height: 60px;
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
}
</style>